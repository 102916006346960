import saleService from '@/modules/payments/services/saleService';
import {
  CHECKOUT_ACTIONS,
  CHECKOUT_ITEM_TYPES,
  DISCOUNT_TYPES,
  ENTITY_TYPES,
} from '@/modules/payments/constants/pos';
import { i18n } from '@/plugins/i18n';
import businessDateFormats from '@/modules/common/filters/BusinessDateFormats';
import taxUtil from '@/modules/payments/utils/taxUtil';

const initialState = {
  matterUid: null,
  billableItems: [],
  discount: { type: DISCOUNT_TYPES.fixed, value: 0 },
  newProducts: [],
};

const state = { ...initialState };

const helpers = {
  getItemPriceBreakdown(taxMode, item, paidAmount) {
    let discountValue = 0;
    const itemHasDiscount = item.discount?.value;
    if (itemHasDiscount) {
      if (item.discount.type === 'precentage') {
        discountValue = item.price * (item.discount.value / 100);
      } else {
        discountValue = item.discount.value;
      }
    }

    const price = item.price - discountValue;
    let priceBreakdown = taxUtil.calcPriceBreakdown(taxMode, price, item.taxes);

    if (paidAmount) {
      priceBreakdown = taxUtil.calcPriceBreakdownWithPartialPayment(priceBreakdown, item.taxes, paidAmount, taxMode);
    }

    if (itemHasDiscount) {
      priceBreakdown.priceBeforeDiscount = item.price;
      priceBreakdown.discountValue = discountValue;
    }

    return priceBreakdown;
  },
  getSalePriceBreakdown(checkoutItems, discount, defaultCurrency) {
    const currency = checkoutItems[0]?.currency ?? defaultCurrency;
    const netTotal = checkoutItems.reduce((acc, item) => acc + item.priceBreakdown.netTotal, 0);
    const taxTotal = checkoutItems.reduce((acc, item) => acc + item.priceBreakdown.taxTotal, 0);
    let discountValue = discount.value;
    if (discount.type === DISCOUNT_TYPES.percentage) {
      discountValue = ((netTotal + taxTotal) * discount.value) / 100;
    }
    const grandTotal = netTotal + taxTotal - discountValue;

    return {
      currency,
      netTotal,
      taxTotal,
      discountValue,
      grandTotal,
      discount,
    };
  },
  getClientDetails(client, matter) {
    if (!client || !matter) {
      return initialState.clientDetails;
    }

    const matterName = matter.display_name === client.full_name ? null : matter.display_name;

    return {
      client_uid: client.uid,
      matter_uid: matter.uid,
      clientName: client.full_name,
      matterName,
      avatar: {
        size: 'sm',
        imagePath: client.photo_path,
        colorId: client.color_id,
      },
    };
  },
  calcCheckoutItemData({
    uid, type, paidAmount = 0, ...rest
  }, rootGetters) {
    const taxMode = rootGetters['PaymentSettingsStore/taxMode'];
    const checkoutItemDetails = helpers.getCheckoutItemDetails(rootGetters, uid, type, taxMode);

    Object.assign(checkoutItemDetails, rest);

    // TODO: send discount data to calc price
    const priceBreakdown = helpers.getItemPriceBreakdown(taxMode, checkoutItemDetails, paidAmount);

    return {
      ...checkoutItemDetails,
      uid,
      type,
      readonly: paidAmount > 0,
      priceBreakdown,
    };
  },
  getCheckoutItemDetails(rootGetters, uid, type, taxMode) {
    switch (type) {
      case CHECKOUT_ITEM_TYPES.ClientBookingPackage: {
        const clientPackage = rootGetters['ClientPackageStore/getClientPackageByUid'](uid);
        return helpers.getClientBookingPackageData(clientPackage, taxMode);
      }
      case CHECKOUT_ITEM_TYPES.ProductOrder: {
        const productOrder = rootGetters['ProductOrderStore/getProductOrderByUid'](uid);
        const product = rootGetters['ProductsStore/getProductByUid'](productOrder.product_id);
        return helpers.getProductOrderData(productOrder, product, taxMode);
      }
      case CHECKOUT_ITEM_TYPES.Meeting: {
        const appointment = rootGetters['AppointmentStore/getAppointmentByUid'](uid);
        const service = rootGetters['ServicesStore/getServiceByUid'](appointment.service_id);
        return helpers.getMeetingsData(appointment, service, taxMode);
      }
      case CHECKOUT_ITEM_TYPES.EventAttendance: {
        const eventAttendance = rootGetters['EventAttendanceStore/getEventAttendanceByUid'](uid);
        const service = rootGetters['ServicesStore/getServiceByUid'](eventAttendance.event_service_uid);
        return helpers.getEventAttendanceData(eventAttendance, service, taxMode);
      }
      case CHECKOUT_ITEM_TYPES.Service: {
        const service = rootGetters['ServicesStore/getServiceByUid'](uid);
        return helpers.getServiceData(service);
      }
      case CHECKOUT_ITEM_TYPES.Product: {
        let product = state.newProducts.find((elem) => elem.id === uid);
        if (product === null || product === undefined) {
          product = rootGetters['ProductsStore/getProductByUid'](uid);
        }
        return helpers.getProductData(product);
      }
      case CHECKOUT_ITEM_TYPES.Custom:
        return { entity_uid: uid, entity_type: type };
      default:
        return {};
    }
  },
  getEntityDescription(entityType, entity) {
    switch (entityType) {
      case CHECKOUT_ITEM_TYPES.EventAttendance:
      case CHECKOUT_ITEM_TYPES.Meeting: {
        const formattedDate = businessDateFormats.dateNoCurrentYear(entity.start_time || entity.real_start_time);
        return i18n.t('point_of_sale.checkout_items.meeting_description', { formattedDate })
          + (entity.description ? `\n${entity.description}` : '');
      }
      case CHECKOUT_ITEM_TYPES.ProductOrder:
      case CHECKOUT_ITEM_TYPES.ClientBookingPackage: {
        const formattedDate = entity.created_at_h || businessDateFormats.dateNoCurrentYear(entity.created_at);
        return i18n.t('point_of_sale.checkout_items.general_description', { formattedDate })
          + (entity.description ? `\n${entity.description}` : '');
      }
      default:
        return null;
    }
  },
  getDatetimeInfo(entityType, entity) {
    switch (entityType) {
      case CHECKOUT_ITEM_TYPES.EventAttendance:
      case CHECKOUT_ITEM_TYPES.Meeting: {
        const formattedDate = businessDateFormats.dateNoCurrentYear(entity.start_time || entity.real_start_time);
        const formattedStartTime = businessDateFormats.time(entity.start_time || entity.real_start_time);
        const formattedEndTime = businessDateFormats.time(entity.end_time || entity.real_end_time);
        const formattedDuration = businessDateFormats.duration(entity.duration);
        return i18n.t('point_of_sale.checkout_items.meeting_datetime_info', {
          date: formattedDate,
          start_time: formattedStartTime,
          end_time: formattedEndTime,
          duration: formattedDuration,
        });
      }
      default:
        return null;
    }
  },
  getClientBookingPackageData(clientPackage, taxMode) {
    const description = helpers.getEntityDescription(CHECKOUT_ITEM_TYPES.ClientBookingPackage, clientPackage);

    return {
      entity_uid: clientPackage.id,
      entity_type: CHECKOUT_ITEM_TYPES.ClientBookingPackage,
      matter_uid: clientPackage.conversation_id,
      name: clientPackage.name,
      description,
      quantity: 1,
      currency: clientPackage.currency,
      price: taxUtil.getPriceByTaxMode(taxMode, clientPackage.net_price, clientPackage.price),
      taxes: clientPackage.taxes || [],
      image: clientPackage.image_path,
    };
  },
  getProductOrderData(productOrder, product, taxMode) {
    const description = helpers.getEntityDescription(CHECKOUT_ITEM_TYPES.ProductOrder, productOrder);

    return {
      entity_uid: productOrder.id,
      entity_type: CHECKOUT_ITEM_TYPES.ProductOrder,
      matter_uid: productOrder.matter_uid,
      name: productOrder.name,
      description,
      quantity: 1,
      currency: productOrder.currency,
      price: taxUtil.getPriceByTaxMode(taxMode, productOrder.price, productOrder.total_price),
      taxes: productOrder.taxes || [],
      image: product?.image_url,
    };
  },
  getMeetingsData(appointment, service, taxMode) {
    const description = helpers.getEntityDescription(CHECKOUT_ITEM_TYPES.Meeting, appointment);
    const paymentStatus = appointment.payment_status;

    return {
      entity_uid: appointment.id,
      entity_type: CHECKOUT_ITEM_TYPES.Meeting,
      matter_uid: appointment.matter_uid,
      name: appointment.title,
      description,
      quantity: 1,
      currency: appointment.currency,
      price: taxUtil.getPriceByTaxMode(taxMode, paymentStatus?.net_price || 0, paymentStatus?.price || 0),
      taxes: paymentStatus?.taxes || [],
      image: appointment.image_url ?? service?.image_path,
      linked_uid: appointment.linked_booking_uid,
      datetime_info: helpers.getDatetimeInfo(CHECKOUT_ITEM_TYPES.Meeting, appointment),
    };
  },
  getEventAttendanceData(eventAttendance, service, taxMode) {
    const description = helpers.getEntityDescription(CHECKOUT_ITEM_TYPES.EventAttendance, eventAttendance);

    return {
      entity_uid: eventAttendance.id,
      entity_type: CHECKOUT_ITEM_TYPES.EventAttendance,
      matter_uid: eventAttendance.matter.uid,
      name: eventAttendance.event_title,
      description,
      quantity: 1,
      currency: eventAttendance.currency,
      price: taxUtil.getPriceByTaxMode(taxMode, eventAttendance.net_price, eventAttendance.price),
      taxes: eventAttendance.taxes || [],
      image: eventAttendance.image_url ?? service?.image_path,
    };
  },
  getServiceData(service) {
    return {
      entity_uid: service.id,
      entity_type: CHECKOUT_ITEM_TYPES.Service,
      name: service.name,
      description: service.description,
      quantity: 1,
      currency: service.currency,
      price: service.price,
      taxes: service.taxes || [],
      image: service.image_path,
    };
  },
  getProductData(product = {}) {
    return {
      entity_uid: product.id,
      entity_type: CHECKOUT_ITEM_TYPES.Product,
      name: product.name,
      description: product.description,
      quantity: 1,
      currency: product.currency,
      price: product.price,
      taxes: product.taxes || [],
      image: product.image_url,
    };
  },
  getSaleItems(checkoutItems, billableItems) {
    return billableItems.map((item, index) => {
      const checkoutItem = checkoutItems[index];
      const itemData = {
        entity_uid: item.uid,
        entity_type: item.type,
        amount: item.price,
        entity_name: checkoutItem.name,
      };

      if (item.discount?.value) {
        itemData.amount = parseFloat(checkoutItem.priceBreakdown.priceBeforeDiscount);
        if (item.discount.type === 'precentage') {
          itemData.discount = {
            percent: item.discount.value,
          };
        } else {
          itemData.discount = {
            amount: item.discount.value,
          };
        }
      }

      if (ENTITY_TYPES[item.type]) {
        itemData.description = checkoutItem.description;
        itemData.amount ??= checkoutItem.price;
        itemData.taxes = checkoutItem.taxes;
      }
      return itemData;
    });
  },
};

const mutations = {
  setMatterUid(state, matterUid) {
    state.matterUid = matterUid;
  },
  setBillableItems(state, billableItems) {
    state.billableItems = billableItems;
  },
  addBillableItem(state, billableItem) {
    state.billableItems = [...state.billableItems, billableItem];
  },
  removeBillableItem(state, index) {
    state.billableItems = state.billableItems.filter((_, i) => i !== index);
  },
  updateBillableItem(state, { index, billableItem }) {
    const newBillableItem = {
      ...state.billableItems[index],
      ...billableItem,
    };
    state.billableItems = state.billableItems.with(index, newBillableItem);
  },
  removeMultipleBillableItems(state, indexes) {
    state.billableItems = state.billableItems.filter((_, i) => !indexes.includes(i));
  },
  setDiscount(state, discount) {
    state.discount = discount;
  },
  clearData(state) {
    Object.assign(state, initialState);
  },
  addNewProduct(state, product) {
    state.newProducts = [...state.newProducts, product];
  },
};

const actions = {
  async setMatterUid({ commit, dispatch, rootGetters }, matterUid) {
    let matter = rootGetters['MatterStore/matterByUid'](matterUid);
    if (!matter && matterUid) {
      await dispatch('MatterStore/getMatter', matterUid, { root: true });
      matter = rootGetters['MatterStore/matterByUid'](matterUid);
    }

    const clientUid = matter?.contacts[0]?.uid;
    const client = rootGetters['ClientStore/client'](clientUid);
    if (!client && clientUid) {
      await dispatch('ClientStore/getClient', clientUid, { root: true });
    }

    commit('setMatterUid', matterUid);
  },
  async setBillableItemsAndFetchData({ getters, dispatch }, {
    billableItems,
    replaceList = true,
    highlight = false,
  }) {
    if (getters.selectedMatter && billableItems.length) {
      const uidsByType = billableItems.reduce((acc, item) => {
        acc[item.type] ??= [];
        acc[item.type].push(item.uid);
        return acc;
      }, {});

      const promises = Object.entries(uidsByType).map(([type, uids]) => {
        switch (type) {
          case CHECKOUT_ITEM_TYPES.ClientBookingPackage:
            return dispatch('ClientPackageStore/fetchClientPackages', { uids }, { root: true });
          case CHECKOUT_ITEM_TYPES.ProductOrder:
            return dispatch('ProductOrderStore/fetchProductOrders', { uids }, { root: true });
          case CHECKOUT_ITEM_TYPES.Meeting:
            return dispatch('AppointmentStore/fetchAppointments', {
              uids,
              matterUid: getters.selectedMatter?.uid,
              clientUid: getters.selectedClient?.uid,
            }, { root: true });
          case CHECKOUT_ITEM_TYPES.EventAttendance:
            return dispatch('EventAttendanceStore/fetchEventAttendances', {
              uids,
              matterUid: getters.selectedMatter?.uid,
              clientUid: getters.selectedClient?.uid,
            }, { root: true });
          default:
            return null;
        }
      });

      await Promise.all(promises);
    }

    if (highlight) {
      billableItems.forEach((item) => {
        // eslint-disable-next-line no-param-reassign
        item.highlighted = true;
      });
    }

    if (replaceList) {
      dispatch('setBillableItems', billableItems);
    } else {
      billableItems.forEach((item) => {
        dispatch('addBillableItem', item);
      });
    }
  },
  setBillableItems({ commit }, billableItems) {
    commit('setBillableItems', billableItems);
  },
  addBillableItem({ commit }, billableItem) {
    commit('addBillableItem', billableItem);
  },
  removeBillableItem({ commit }, index) {
    commit('removeBillableItem', index);
  },
  updateBillableItem({ commit }, { index, billableItem }) {
    commit('updateBillableItem', { index, billableItem });
  },
  removeLinkedBillableItems({ commit, getters }, linkedItems) {
    const indexes = [];
    linkedItems.forEach((linkedItem) => {
      indexes.push(getters.checkoutItems.findIndex((i) => i.entity_uid === linkedItem.uid && i.entity_type === linkedItem.type));
    });
    commit('removeMultipleBillableItems', indexes);
  },
  setDiscount({ commit }, discount) {
    commit('setDiscount', discount);
  },
  clearData({ commit }) {
    commit('clearData');
  },
  async createSaleAndTakePayment({ state, getters }, checkoutAction) {
    const { currency } = getters.checkoutItems[0];
    const saleItems = helpers.getSaleItems(getters.checkoutItems, state.billableItems);
    const sale = await saleService.createSale(getters.selectedMatter.uid, currency, saleItems);

    if (checkoutAction === CHECKOUT_ACTIONS.FreeCheckout) {
      await saleService.completeSale(sale.uid, { new_api: true });
    }
    return sale;
  },
  addNewProduct({ commit }, product) {
    commit('addNewProduct', product);
  },
};

const getters = {
  // eslint-disable-next-line no-shadow
  selectedMatter: (state, getters, rootState, rootGetters) => rootGetters['MatterStore/matterByUid'](state.matterUid),
  // eslint-disable-next-line no-shadow
  selectedClient: (state, getters, rootState, rootGetters) => rootGetters['ClientStore/client'](getters.selectedMatter?.contacts[0]?.uid),
  // eslint-disable-next-line no-shadow
  clientDetails: (state, getters) => helpers.getClientDetails(getters.selectedClient, getters.selectedMatter),
  // eslint-disable-next-line no-shadow
  checkoutItems: (state, getters, rootState, rootGetters) => state.billableItems
    .map((item) => helpers.calcCheckoutItemData(item, rootGetters)),
  // eslint-disable-next-line no-shadow
  priceBreakdown: (state, getters, rootState, rootGetters) => helpers.getSalePriceBreakdown(getters.checkoutItems, state.discount, rootGetters['PaymentSettingsStore/currency']),
  // eslint-disable-next-line no-shadow
  getCountByUidAndType: (state) => (uid, type) => state.billableItems.filter((item) => item.uid === uid && item.type === type).length,
  // eslint-disable-next-line no-shadow
  filterByCurrency: (state, getters, rootState, rootGetters) => {
    const currency = rootGetters['PaymentSettingsStore/currency'];
    return (array) => array.filter((item) => item.currency === currency);
  },
  // eslint-disable-next-line no-shadow
  getLinkedItems: (state, getters) => (item) => {
    if (!item?.linked_uid) {
      return [];
    }
    return getters.checkoutItems.filter((i) => i.linked_uid === item.linked_uid);
  },
  newProducts: (state) => state.newProducts,
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
