import Vue from 'vue';
import vcitaLogo from '@/assets/images/vcita-logo-white.svg';
import LayoutConstants from '@/constansts/layoutConstants';
import businessService from '@/modules/business/services/businessService';
import businessDateFormats from '@/modules/common/filters/BusinessDateFormats';
import businessContactDetailsStore from '@/modules/business/store/businessContactDetailsStore';

const state = {
  matterData: {
    matterTerm: 'clients',
    icon: 'icon-clients-POV',
    translation: 'common.navigation.clients',
  },
  branding: {
    colors: {
      primary: '#FFFFFF', // white so that the menu doesn't have a background color before brand is loaded
      secondary: '#0093B8',
      text: '#ffffff',
    },
    logo: null,
    poweredBy: {
      parent_logo: '',
      intandem_logo: '',
    },
  },
  isBrandingLoaded: false,
  features: [],
  featureFlagsLoaded: false,
  attributes: {},
};

const helpers = {
  mapBrandingResponse: (branding, rootGetters) => {
    const poweredByMapped = {};
    const isMobile = rootGetters['CommonStore/isMobile'];

    if (isMobile || helpers.isPrimaryColorWhite(branding.colors.primary)) {
      poweredByMapped.parent_logo = branding.powered_by?.parent_logo_dark || '';
      poweredByMapped.intandem_logo = branding.powered_by?.intandem_logo_dark || '';
    } else {
      poweredByMapped.parent_logo = branding.powered_by?.parent_logo_bright || '';
      poweredByMapped.intandem_logo = branding.powered_by?.intandem_logo_bright || '';
    }
    const brandingObject = { colors: branding.colors, logo: branding.logo || vcitaLogo, poweredBy: poweredByMapped };
    return brandingObject;
  },
  isPrimaryColorWhite: (primaryColor) => ['#ffffff', '#fff', '#ffffffff', 'rgb(255, 255, 255)'].includes(primaryColor.toLowerCase()),
  supportArticleUrl: (articleCode, attributes, brandedZendeskEnabled) => {
    if (brandedZendeskEnabled && attributes?.directory_settings?.faq_url) {
      const url = new URL(attributes.directory_settings.faq_url);
      url.searchParams.append('article_code', articleCode);

      return url.toString();
    }
    return '';
  },
  brandedZendeskSupportArticleUrl: (articleCode, attributes, rootGetters) => {
    const brandedZendeskEnabled = rootGetters['BusinessStore/hasFeature']('multi_branded_zendesk');
    return helpers.supportArticleUrl(articleCode, attributes, brandedZendeskEnabled);
  },
  brandedSupportArticleUrl: (articleCode, attributes, rootGetters, {
    partnerDirectoryUrl = '',
    directUrl = '',
  } = {}) => {
    const zendeskUrl = helpers.brandedZendeskSupportArticleUrl(articleCode, attributes, rootGetters);
    if (zendeskUrl) {
      return zendeskUrl;
    }
    if (partnerDirectoryUrl && rootGetters['BusinessStore/isPartnerDirectory']) {
      return partnerDirectoryUrl;
    }
    if (directUrl) {
      return directUrl;
    }
    return null;
  },
  buildMatterData: (matterTerm) => {
    const translationKey = LayoutConstants.MATTER_TRANSLATIONS_KEYS[matterTerm];
    const matterData = {
      matterTerm,
      icon: `icon-${matterTerm.toLowerCase()}-POV`,
      translation: translationKey,
    };
    return matterData;
  },
};

const mutations = {
  setMatterData(state, matterData) {
    state.matterData = matterData;
  },
  setBranding(state, branding) {
    state.branding = branding;
    state.isBrandingLoaded = true;
  },
  setAttribute(state, payload) {
    state.attributes = { ...state.attributes, [payload.key]: payload.value };
  },
  setAttributes(state, attributes) {
    state.attributes = attributes;
  },
  setFeatureFlags(state, features) {
    state.features = features;
  },
  setFeatureFlagsLoaded(state, loaded) {
    state.featureFlagsLoaded = loaded;
  },
};

const actions = {
  async fetchBranding({ commit, rootGetters }) {
    try {
      const branding = await businessService.fetchBranding();
      if (branding) {
        const mappedBranding = helpers.mapBrandingResponse(branding, rootGetters);
        commit('setBranding', mappedBranding);
      }
    } catch (e) {
      console.error('error', e);
    }
  },

  async fetchAttributes({ commit, rootGetters }) {
    try {
      const attributes = await businessService.fetchAttributes();
      commit('setAttributes', attributes);
      // eslint-disable-next-line camelcase
      const { locale, time_format } = attributes;
      Vue.prototype.$ds.setLocale(locale || 'en');
      Vue.prototype.setLocale(locale || 'en');
      Vue.prototype.addI18nModule('common-pov');
      businessDateFormats.setLocale(locale, time_format);

      window.supportArticleUrl = (
        articleCode,
        {
          partnerDirectoryUrl = '',
          directUrl = '',
        } = {},
      ) => helpers.brandedSupportArticleUrl(articleCode, attributes, rootGetters, {
        partnerDirectoryUrl,
        directUrl,
      });

      window.openSupportArticle = (articleCode, {
        partnerDirectoryUrl = '',
        directUrl = '',
      } = {}) => {
        const url = window.supportArticleUrl(articleCode, {
          partnerDirectoryUrl,
          directUrl,
        });
        if (url) {
          window.open(`${url.toString()}`, '_blank');
        }
      };

      const matterTerm = attributes.matter_term;
      if (matterTerm) {
        commit('setMatterData', helpers.buildMatterData(matterTerm));
      }
    } catch (e) {
      console.error('error', e);
    }
  },
  setAttribute({ commit }, payload) {
    commit('setAttribute', payload);
    if (payload.key === 'time_format') {
      businessDateFormats.setLocale(null, payload.value);
    }
  },

  async fetchFeatureFlags({ rootGetters, commit }) {
    try {
      const businessUid = rootGetters['AuthStore/businessUid'];
      const features = await businessService.fetchFeaturesFlags(businessUid);
      commit('setFeatureFlagsLoaded', features?.length > 0);
      commit('setFeatureFlags', features);
    } catch (e) {
      console.error('error', e);
    }
  },
};

const getters = {
  matterTerm: (state) => state.matterData.matterTerm,
  matterTermIcon: (state) => state.matterData.icon,
  matterTermTranslation: (state) => state.matterData.translation,
  primaryColor: (state) => state.branding.colors.primary,
  secondaryColor: (state) => state.branding.colors.secondary,
  textColor: (state) => state.branding.colors.text,
  isBrandingLoaded: (state) => state.isBrandingLoaded,
  logo: (state) => state.branding.logo,
  parentPoweredByLogo: (state) => state.branding.poweredBy.parent_logo,
  inTandemPoweredByLogo: () => state.branding.poweredBy.intandem_logo,
  attributes: (state) => state.attributes,
  featureFlags: (state) => state.features,
  featureFlagsLoaded: (state) => state.featureFlagsLoaded,
  hasFeature: (state) => (fName) => state.features.includes(fName),
  appHost: (state) => state.attributes.app_host,
  directorySettings: (state) => state.attributes?.directory_settings || {},
  isPartnerDirectory: (state) => (
    !!state.attributes?.directory_settings?.is_whitelabel || !!state.attributes?.directory_settings?.is_co_branded_managed
  ),
  getDirectoryLogoutUrl: (state) => (
    state.attributes?.directory_settings?.logout_url
  ),
  hasHelpLinks: (state) => !!state.attributes?.directory_settings?.custom_support_url
    || !!state.attributes?.directory_settings?.faq_url
    || !!state.attributes?.directory_settings?.help_links,
  supportArticleUrl: (state) => (articleCode) => helpers.supportArticleUrl(articleCode, state.attributes, getters.hasFeature(state)('multi_branded_zendesk')),
  isSpecialDirectory: (state) => (
    state.attributes?.directory_settings?.is_whitelabel || state.attributes?.directory_settings?.is_co_branded_managed || false
  ),
  // TODO: test the isBranded getter, is it the same as before?
  // isBranded: state => state.branding && (state.branding.whitelabel || state.branding.co_branded_managed), <- frontge-vue
  isBranded: (state) => state.attributes?.directory_settings?.is_whitelabel || state.attributes?.directory_settings?.is_co_branded_managed,
  locale: (state) => state.attributes.locale || 'en',
};

const modules = {
  BusinessContactDetailsStore: businessContactDetailsStore,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules,
};
