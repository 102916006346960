<template>
  <div ref="component" class="campaigns-widget pa-6">
    <VcLayout column :fill-height="!campaigns.length">
      <VcLayout class="widget-title" @click="openAnnouncements">{{ $t('widget.campaigns_widget.recent_campaigns') }}</VcLayout>
      <VcLayout class="sections-wrapper mt-6">
        <VcLayout column class="recent-campaigns">
          <div v-if="campaigns.length" class="recent-campaigns-wrapper">
            <CampaignItem
              v-for="(campaign) in campaigns"
              :title="campaign.name"
              :imagePath="campaign.image_url"
              :type="campaign.automatic_campaign? 'automatic' : 'manual'"
              :date="campaign.last_sent_at_date"
              :stats="statsCounts(campaign)"
              :key="campaign.uid"
              class="campaign-item-container mb-7"
              @click="onCampaignClick(campaign.uid)"
            />
          </div>
          <VcLayout column>
            <div v-if="!campaigns.length" class="empty-wrapper">
              <VcEmptyState
                :subtitle="$t('widget.campaigns_widget.empty_state_subtitle')"
                title=""
                :button="$t('widget.campaigns_widget.new_campaign')"
                :image="require('@/assets/images/widgets/no_campaigns.svg')"
                outlined-button
                size="md"
                @click="openNewAutoCampaign"
              />
            </div>
          </VcLayout>
        </VcLayout>
      </VcLayout>
    </VcLayout>
  </div>
</template>

<script>
import CampaignItem from '@/modules/widgets/campaignsWidget/components/CampaignItem.vue';
import campaignsWidgetService from '@/modules/widgets/campaignsWidget/services/campaignsWidgetService';

export default {
  name: 'CampaignsWidget',
  components: { CampaignItem },
  data() {
    return {
      componentWidth: 0,
      isLoading: false,
      campaigns: [],
    };
  },
  mounted() {
    window.addEventListener('resize', this.updateComponentWidth);
  },
  async created() {
    this.isLoading = true;
    this.addI18nModule('widgets-pov');
    await this.init();
    this.isLoading = false;
    this.$emit('ready');
    await this.$nextTick();
    this.updateComponentWidth();
  },
  methods: {
    updateComponentWidth() {
      if (this.$refs.component) {
        this.componentWidth = this.$refs.component.offsetWidth;
      }
    },
    async init() {
      const recentCampaignsArray = await campaignsWidgetService.getCampaigns();
      if (recentCampaignsArray) this.campaigns = recentCampaignsArray.recent;
    },
    handleCampaignsNavigation(subjectType, campaignUid = '') {
      const campaignsPath = '/app/campaigns';
      switch (subjectType) {
        case 'auto_campaigns':
          return this.onNavigate(`${campaignsPath}/automatic`, { createNewCampaign: 'true' });
        case 'campaign_stats':
          return this.onNavigate(`${campaignsPath}/${campaignUid}/statistics`);
        case 'announcements':
          return this.onNavigate(`${campaignsPath}/list/campaigns`);
        default:
          return this.onNavigate(campaignsPath);
      }
    },
    onNavigate(path, queryParams) {
      this.$emit('sdkAction', 'Navigate', {
        type: 'relative',
        path,
        queryParams,
      });
    },
    onCampaignClick(campaignUid) {
      this.handleCampaignsNavigation('campaign_stats', campaignUid);
    },
    openAnnouncements() {
      this.handleCampaignsNavigation('announcements');
    },
    openNewAutoCampaign() {
      this.handleCampaignsNavigation('auto_campaigns');
    },
    statsCounts(campaign) {
      return {
        sent: campaign.added_count,
        viewed: campaign.view_count,
        clicked: campaign.click_count,
        engaged: campaign.acted_count,
      };
    },
  },
};
</script>

<style scoped lang="scss">
$xSmall: 359px;
$small: 485px;
$medium: 598px;
$large: 684px;
$xLarge: 807px;

.campaigns-widget {
  color: var(--gray-darken-5);
  container-type: inline-size;
  line-height: normal;
  text-align: start;
  align-self: normal;
  height: 100%;
  min-width: 233px;
}
.widget-title {
  white-space: nowrap;
  font-size: var(--font-size-medium2);
  font-weight: var(--font-weight-large2);
  cursor: pointer;

  &:hover {
    color: var(--link-color);
  }
}
.sections-wrapper {
  white-space: nowrap;

}
.recent-campaigns {
  box-sizing: border-box;
  justify-content: flex-start;
  width: 100%;
  ::v-deep {
    .VcEmptyState {
      height: 100%;
      .VcEmptyStateImage {
        margin: 0 auto;
        width: 66px;
        height: 66px;
      }
    }
  }
  .campaign-item-container {
    height: 80px;
  }
}
.empty-wrapper {
  align-content: center;
}
</style>
