<template>
  <VcInputModal size="md"
                ref="inputModal"
                close-button
                :show-dialog="visible"
                :title="editItemTitleText"
                :ok-button-label="editItemAgreeText()"
                :cancel-button-label="editItemCancelText"
                :show-back-button="false"
                @onCloseButtonClicked="closeModal"
                @onCancelClicked="closeModal"
                @onOkClicked="saveItem"
                class="edit-item-modal">
    <template #content>
      <VcLayout column>
        <VcLayout class="py-2 mb-4">
          <ItemImage :image="item.image" :type="item.type" class="edit-item-modal__image"/>

          <VcLayout column class="flex-fill justify-center ms-3">
            <div class="edit-item-modal__title text-truncate">{{ item.name }}</div>
            <div class="edit-item-modal__subtitle">
              {{ $priceDisplay(price, item.currency) }}
            </div>
          </VcLayout>
        </VcLayout>

        <VcFocusArea class="edit-item-modal__inputs price" :class="{ column: $isMobile(), mobile: $isMobile() }">
          <!--  eslint-disable-next-line vuejs-accessibility/no-autofocus -->
          <VcCounter v-model="price" type="number" :min="0" :autofocus="priceAutoFocus"
                     @input="priceChanged"
                     :prefix="$currencySymbol(item.currency)"
                     :rules="priceRules"
                     :label="priceLabel"/>
          <VcCounter v-if="showQuantity" :value="item.quantity" type="number" disabled
                     :label="$t('point_of_sale.edit_item_dialog.quantity')"/>
          <VcTextField v-if="taxesStr && !forcePriceEdit" :value="taxesStr" disabled
                       :label="$t('point_of_sale.edit_item_dialog.taxes')"/>
          <div v-if="forcePriceEdit" :class="{'tax-picker': !$isMobile()}">
            <TaxPicker  :taxes="getTaxes"
                        :isMobile="$isMobile()"
                        v-model="selectedTaxes"
                        :class="{'mobile': $isMobile()}"></TaxPicker>
          </div>
        </VcFocusArea>
        <VcFocusArea class="edit-item-modal__inputs discount" :class="{ column: $isMobile(), mobile: $isMobile()}" v-if="hasDiscountFF">
          <div class="discount-value">
            <VcTextField data-qa="discount-value"
              :label="$t('point_of_sale.edit_item_dialog.discount')"
              type="number"
              :value="discount.value"
              @input="onChangeDiscount"
              :disabled="discountDisabled"
              :prefix="getDiscountPrefix"
              :rules="discountRules"
              step="0.01"/>
          </div>
          <div :class="{ mobile: $isMobile() }" class="discount-type" :disabled="discountDisabled">
            <VcSegmentedControl
              data-qa="discount-types"
              mandatory class="discount-types"
              :items="discountTypes"
              :value="selectedDiscountType"
              @change="onSelectDiscountType"/>
          </div>
        </VcFocusArea>
      </VcLayout>
    </template>
  </VcInputModal>
</template>

<script>
import ItemImage from '@/modules/payments/components/posTable/ItemImage.vue';
import { TAX_MODES } from '@/modules/payments/utils/taxUtil';
import { mapGetters } from 'vuex';
import TaxPicker from '../taxes/TaxPicker.vue';

export default {
  name: 'EditItemModal',
  components: { ItemImage, TaxPicker },
  model: {
    prop: 'visible',
    event: 'update:visible',
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      required: false,
    },
    taxMode: {
      type: String,
      required: false,
      validator: (value) => Object.values(TAX_MODES).includes(value),
    },
    forcePriceEdit: {
      type: Boolean,
      default: false,
    },
    taxes: {
      type: Array,
      default: () => ([]),
    },
  },
  watch: {
    item: {
      handler() {
        this.reCalculatePrice();
      },
    },
    visible: {
      handler() {
        this.reCalculatePrice();
      },
    },
  },
  data() {
    return {
      price: 0,
      discount: {
        type: 'precentage',
        value: null,
      },
      selectedTaxes: [],
    };
  },
  mounted() {
    this.reCalculatePrice();
  },
  computed: {
    ...mapGetters('BusinessStore', ['hasFeature']),
    priceAutoFocus() {
      return this.price === null;
    },
    editItemTitleText() {
      return this.$t('point_of_sale.edit_item_dialog.title');
    },
    editItemCancelText() {
      return this.$t('point_of_sale.edit_item_dialog.cancel');
    },
    priceRules() {
      return [
        (v) => !!v || this.$t('point_of_sale.edit_item_dialog.grand_total_validation.required'),
        (v) => v >= 0 || this.$t('point_of_sale.edit_item_dialog.grand_total_validation.min'),
      ];
    },
    priceLabel() {
      if (this.taxMode === TAX_MODES.include) {
        return this.$t('point_of_sale.edit_item_dialog.grand_total');
      }
      return this.$t('point_of_sale.edit_item_dialog.net_total');
    },
    taxesStr() {
      return this.item?.taxes?.map((tax) => `${tax.name} (${tax.rate}%)`).join(', ');
    },
    getTaxes() {
      return this.taxes || [];
    },
    itemToSave() {
      const res = {
        price: this.price,
      };
      if (this.hasDiscountFF && this.discount.value > 0) {
        res.discount = {
          value: this.discount.value,
          type: this.discount.type,
        };
      }
      res.taxes = this.selectedTaxes;
      return res;
    },
    showQuantity() {
      // Will be optional in the future
      return false;
    },
    // ************** discount ************** //
    currencySymbol() {
      return this.$currencySymbol(this.item.currency);
    },
    discountTypes() {
      return [
        { id: 'fixed', label: this.currencySymbol },
        { id: 'precentage', label: '%' },
      ];
    },
    selectedDiscountType() {
      return this.discount.type === 'precentage' ? 1 : 0;
    },
    getDiscountPrefix() {
      return this.discount.type === 'precentage' ? '%' : this.currencySymbol;
    },
    discountRules() {
      let discountRule;
      if (this.discount.type === 'precentage') {
        discountRule = (v) => +v <= 100 || this.$t('point_of_sale.edit_item_dialog.discount_max_validation', { value: '100%' });
      } else {
        discountRule = (v) => +v <= this.price || this.$t('point_of_sale.edit_item_dialog.discount_max_validation', { value: 'price' });
      }

      return [
        (v) => v >= 0 || this.$t('point_of_sale.edit_item_dialog.discount_min_validation'),
        discountRule,
      ];
    },
    discountDisabled() {
      return parseFloat(this.price) === 0;
    },
    hasDiscountFF() {
      return this.hasFeature('pos_discount');
    },
  },
  methods: {
    editItemAgreeText() {
      if (this.forcePriceEdit) {
        return this.$t('point_of_sale.edit_item_dialog.add');
      }
      return this.$t('point_of_sale.edit_item_dialog.save');
    },
    reCalculatePrice() {
      if (this.visible) {
        if (this.forcePriceEdit) {
          this.price = null;
          this.selectedTaxes = this.item.taxes;
        } else {
          this.price = this.item.price || 0;
        }
        this.discount = this.item?.discount?.value ? this.item.discount : {
          type: 'precentage',
          value: null,
        };
      }
    },
    closeModal() {
      this.$emit('update:visible', false);
    },
    saveItem({ valid }) {
      if (valid) {
        const discountValue = this.discount?.value ? this.discount.value : 0;
        let discountType = 'No discount';
        if (this.discount?.value) {
          discountType = this.discount.type === 'fixed' ? 'Flat amount' : 'Percentage';
        }
        this.$track(
          'Checkout Edit Item - Success',
          {
            Original_price: this.item?.price,
            New_price: this.price,
            Discount_amount: discountValue,
            Discount_type: discountType,
          },
        );
        this.$emit('update:visible', false);
        this.$emit('onSave', this.itemToSave);
      }
    },
    onSelectDiscountType(val) {
      this.discount.type = val === 0 ? 'fixed' : 'precentage';
      this.$nextTick(() => {
        this.$refs.inputModal.validate();
      });
    },
    priceChanged() {
      if (this.price === 0) {
        this.discount.value = 0;
        this.discount.type = 'fixed';
      }
    },
    onChangeDiscount(value) {
      this.discount.value = value;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "styles/variables.scss";

.edit-item-modal {
  &__image {
    width: var(--size-value10);
    height: var(--size-value10);
  }

  &__title, &__subtitle {
    font-size: var(--font-size-x-small);
    font-weight: var(--font-weight-medium2);
  }

  &__title {
    line-height: var(--size-value5);
  }

  &__subtitle {
    color: var(--gray-darken-3);
    line-height: 1em;
  }

  &__inputs {
    gap: var(--size-value3);

    & > * {
      width: 100%;
    }

    &.VcFocusArea {
      background-color: unset;

      &.price {
        padding-bottom: 24px;
        max-height: 96px;
        &.mobile{
          max-height: unset;
          gap: 24px;

          .VcTextInput {
            height: 52px;
          }
        }
      }

      &.discount {
        padding-top: 0px;
        max-height: 72px;

        &.mobile {
          flex-direction: row;
        }
      }
    }

    .discount-type{
      width: 100%;
      margin-top: 4px;
      display: flex;

      .discount-types{
        width: 99px !important;
        max-height: 52px;
      }

      &[disabled] {
        pointer-events: none;

        .discount-types {
          ::v-deep button {

            span {
              color: var(--gray-darken-1);
            }

            &.v-btn--active {
              background-color: var(--gray-lighten-3) !important;
              color: var(--gray-darken-4) !important;
              box-shadow: unset !important;
            }
          }
        }
      }

      &.mobile{
        height: 52px;
        width: auto;
      }
    }

    .discount-value{
      width: 100%;
    }
  }

  .tax-picker {
    width: 100%;
  }
}
</style>
