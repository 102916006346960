import store from '@/store';
import router from '@/router';
import Vue from 'vue';
import businessDateFormats from '@/modules/common/filters/BusinessDateFormats';
import handleNavigation from '@/utils/navigationUtils';
import { getNestedStoreData } from '@/utils/storeUtils';
import eventListenerService from './eventListenerService';
import outgoingPostMessageUtils from './outgoingPostMessageUtils';

// const iframeId = 'angular-iframe';

let isAngularEntryRoute = false;

const handleAngularNavigation = (angularUrl) => {
  const url = new URL(angularUrl);
  const path = url.pathname.substring(url.pathname.indexOf('/app/') + 4);
  const queryString = url.search.replace('?', '');

  // Handle navigation between POV and Angular pages
  const goingTo = router.resolve(path);
  const isGoingToAngular = goingTo.route.meta.isAngular;
  const isCurrentRoutePov = !router.currentRoute.meta.isAngular;
  isAngularEntryRoute = (isCurrentRoutePov && isAngularEntryRoute) || goingTo.route.path === '/entry';
  const isComingFromAngular = !!store.getters['AngularStore/isAngularDisplayed'];

  const urlSearchParams = new URLSearchParams(queryString);
  const queryObj = Object.fromEntries(urlSearchParams.entries());

  /**
   * This handles a situation where the initial route is one that doesn't exist in Angular
   * Since the iFrame is opened with that route, Angular will try to redirect to a landing page.
   * We want to prevent that, but only if the current route is a POV route and only when the app starts.
   */
  if (isCurrentRoutePov && isAngularEntryRoute) {
    isAngularEntryRoute = goingTo.route.path === '/entry';
    return;
  }

  if (router.currentRoute.path === `${path}`) {
    //  Avoid adding child_app to POV url
    delete queryObj.child_app;
    router.replace({ query: queryObj }).catch((err) => {
      console.log(err);
    });
  } else if (isComingFromAngular !== isGoingToAngular) {
    router.push({ path: `${path}`, query: queryObj });
  } else {
    router.replace({ path: `${path}`, query: queryObj });
  }
  store.dispatch('AngularStore/setIsNavigatingToAngular', false);
};

const enterPovPage = (payload) => {
  handleNavigation(payload);
};

const handleGetDataFromStore = (eventData) => {
  const response = getNestedStoreData(eventData.data.path);
  // Echo back the requestId from the incoming message
  outgoingPostMessageUtils.post('store-data-response', {
    response,
    requestId: eventData.data.requestId,
  });
};

const init = () => {
  window.addEventListener('message', (e) => {
    if (!e.origin.includes(window.location.hostname)) return;

    const isAngularDisplayed = store.getters['AngularStore/isAngularDisplayed'];
    const isNavigatingToAngular = store.getters['AngularStore/isNavigatingToAngular'];

    if (typeof e.data !== 'string') {
      switch (e.data.event) {
        case 'update-store':
          /**
           * When updating the store from Angular, we have cases where we want to ignore this, because the page
           * that is trying to make the update is not currently displayed.
           * In other cases, we want to make the update no matter which page is currently being displayed.
           * In that case, the call from Angular should look like this:
           * PovApp.sendMessage('update-store',{type: 'MyStore/setMyData',updateType: 'always',payload: {...myData}})
           */
          // eslint-disable-next-line no-case-declarations
          const angularNavigation = (e.data.data.type === 'AngularStore/setIsNavigatingToAngular' && e.data.data.payload);
          if (e.data.data.updateType === 'always' || isAngularDisplayed || isNavigatingToAngular || angularNavigation) {
            store.dispatch(e.data.data.type, e.data.data.payload);
          }
          break;
        case 'update-quick-actions':
          // TODO: remove this and update the store by using updateType "always"
          // temporary workaround for quick actions until angular dependency is removed
          store.dispatch(e.data.data.type, e.data.data.payload);
          break;
        case 'set-angular-title':
          document.title = e.data.data.title;
          break;
        // Angular has changed route, need to update the displayed url
        case 'location-change':
          // TODO: resolve issue of loading directly to route that does not exist in Angular
          // if (isAngularDisplayed || isNavigatingToAngular) {
          handleAngularNavigation(e.data.data.newUrl);
          // } else {
          //   console.log('Angular tried to go to ', e.data.data.newUrl, ' but was rejected ');
          // }
          break;
        case 'pov-navigation':
          enterPovPage(e.data.data);
          break;
        // POV needs to redirect, this is a vary rare situation
        case 'pov-redirect':
          window.location.href = e.data.data.newUrl;
          break;
        case 'importContacts':
          eventListenerService.emit('importContacts');
          break;
        case 'redirect_to':
          console.log('redirect_to', e.data.data.path);
          window.location.replace(e.data.data.path);
          break;
        case 'setLocale':
          Vue.prototype.setLocale(e.data.data);
          Vue.prototype.addI18nModule('common-pov');
          businessDateFormats.setLocale(e.data.data, null);
          store.dispatch('CommonStore/setIsRtl', e.data.data === 'he');
          break;
        case 'setHeightAsFixed':
          document.getElementsByTagName('body')[0].style.minHeight = 'unset';
          document.getElementsByTagName('body')[0].style.minHeight = `${document.body.scrollHeight}px`;
          break;
        case 'set-fullscreen-mode':
          store.dispatch('AngularStore/setFullscreenState', e.data.data);
          break;
        case 'update-checklist-progress':
          store.dispatch(e.data.data.type, e.data.data.payload);
          break;
        case 'close-modal':
          store.dispatch('ModalStore/closeModal', e.data.data);
          break;
        case 'modalStepBack':
          router.go(-2);
          break;
        case 'bizai-event':
          eventListenerService.emit('bizai-event', e.data);
          break;
        case 'bizai':
          eventListenerService.emit('bizai', e.data);
          break;
        case 'get-data-from-store':
          handleGetDataFromStore(e.data);
          break;
        default:
          eventListenerService.emit('on-message', e.data);
      }
    }
  });
};

export default {
  init,
};

export { getNestedStoreData };
